import { NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';


export default function NavBar(props){
const {pathname} = useLocation();
if(pathname.includes("/uninstall_survey/")){return (null);}
if(pathname.includes("/print-review/")){return (null);}
if(pathname.includes("/print-rate_us/")){return (null);}
if(pathname.includes("/image_downloader-rate_us/")){return (null);}
    return(
        <>
            <div style={{display:"block",color:"orange"}}>
                <img src="../assets/logo192.png" width="48px" style={{padding:"24px 24px 0 0"}}/><span style={{fontFamily:"Garamond", fontSize:"60px"}}><b>SURICATA SOFTWARE</b></span>
            </div>
    <div id="nav-bar-container">
    <ul id="nav-bar">
    <li style={{marginRight:"32px"}}><NavLink to="en/home"><img className="nav-bar-icon" src="../assets/home-icon.svg" alt="home" title="Home" style={{backgroundColor:"black"}}/></NavLink></li>
    <li><NavLink to="en/image_downloader"><img className="nav-bar-icon" src="../assets/ImageDownloader128.png" alt="image downloader" title="Image Downloader" /></NavLink></li>
    <li><NavLink to="en/print"><img className="nav-bar-icon nav-bar-active" src="../assets/Print128.png" alt="print" title="Print!" /></NavLink></li>    
    <li><NavLink to="en/no_swipe"><img className="nav-bar-icon" src="../assets/NoSwipe128.png" alt="no swipe" title="No Swipe" /></NavLink></li>
    <li><a href="https://powermousex.com/en/"><img className="nav-bar-icon" src="../assets/PowerMouseX128.png" alt="power mouse x" title="Power Mouse X" /></a></li>
    <li style={{marginLeft:"32px"}}><NavLink to="en/contact"><img className="nav-bar-icon" src="../assets/email-icon.svg" alt="contact us" title="Contact us" style={{backgroundColor:"black"}}/></NavLink></li>
    </ul>
    <svg width="100%" height="16" z-index="-1" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <rect x="0" y="0" width="100%" height="50%" fill="orange"/>
            </svg>
    </div>
    </>
        )
}