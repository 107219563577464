import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";

export default function PrintRateUs(props){
    const headers = {
        "ar": ["تم تحديث امتداد تنزيل الصور الخاص بك إلى أحدث إصدار"],
        "am": ["የእርስዎ ኤክስቴንሽን የምስል አውርዶ ወደ ቅንብሩ ተሻሽሏል"],
        "bg": ["Вашето разширение за изтегляне на изображения беше актуализирано до най-новата версия"],
        "bn": ["আপনার এক্সটেনশন ইমেজ ডাউনলোডারটি সর্বশেষ সংস্করণে আপডেট করা হয়েছে"],
        "ca": ["La teva extensió de descarregador d'imatges s'ha actualitzat a l'última versió"],
        "cs": ["Vaše rozšíření pro stahování obrázků bylo aktualizováno na nejnovější verzi"],
        "da": ["Din udvidelse billeddownloader blev opdateret til den nyeste version"],
        "de": ["Ihre Erweiterung Bild-Downloader wurde auf die neueste Version aktualisiert"],
        "el": ["Η επέκταση λήψης εικόνας ενημερώθηκε στην πιο πρόσφατη έκδοση"],
        "en": ["Your extension Image downloader was updated to the latest version"],
        "es": ["Su extensión de descargador de imágenes se actualizó a la última versión"],
        "et": ["Teie pildiallalaadija laiend on värskendatud uusimale versioonile"],
        "fa": ["افزونه دانلود کننده تصویر شما به آخرین نسخه به‌روزرسانی شد"],
        "fi": ["Laajennuksesi kuvien latausohjelma päivitettiin uusimpaan versioon"],
        "fil": ["Ang iyong extension Image downloader ay na-update sa pinakabagong bersyon"],
        "fr": ["Votre extension de téléchargeur d'images a été mise à jour vers la dernière version"],
        "gu": ["તમારું એક્સટેન્શન છબી ડાઉનલોડર નવીનતમ સંસ્કરણમાં અપડેટ થયું"],
        "he": ["ההרחבה שלך להורדת תמונות עודכנה לגרסה האחרונה"],
        "hi": ["आपके एक्सटेंशन इमेज डाउनलोडर को नवीनतम संस्करण में अपडेट किया गया"],
        "hr": ["Vaše proširenje za preuzimanje slika ažurirano je na najnoviju verziju"],
        "hu": ["A képletöltő bővítménye frissült a legújabb verzióra"],
        "id": ["Ekstensi pengunduh gambar Anda diperbarui ke versi terbaru"],
        "it": ["La tua estensione per il download di immagini è stata aggiornata all'ultima versione"],
        "ja": ["拡張機能の画像ダウンローダーが最新バージョンに更新されました"],
        "kn": ["ನಿಮ್ಮ ವಿಸ್ತರಣೆ ಚಿತ್ರ ಡೌನ್‌ಲೋಡರ್ ಅನ್ನು ಇತ್ತೀಚಿನ ಆವೃತ್ತಿಗೆ ನವೀಕರಿಸಲಾಗಿದೆ"],
        "ko": ["귀하의 확장 프로그램 이미지 다운로더가 최신 버전으로 업데이트되었습니다"],
        "lt": ["Jūsų plėtinys vaizdų atsisiuntimo programa atnaujinta į naujausią versiją"],
        "lv": ["Jūsu paplašinājums attēlu lejupielādētājs tika atjaunināts uz jaunāko versiju"],
        "ml": ["നിങ്ങളുടെ എക്സ്റ്റെൻഷൻ ഇമേജ് ഡൗൺലോഡർ ഏറ്റവും പുതിയ പതിപ്പിലേക്ക് അപ്‌ഡേറ്റ് ചെയ്യപ്പെട്ടു"],
        "mr": ["तुमचा विस्तार इमेज डाउनलोडर नवीनतम आवृत्तीत अद्यतनित केला गेला आहे"],
        "ms": ["Sambungan pemuat turun imej anda telah dikemas kini ke versi terkini"],
        "nl": ["Uw extensie afbeeldingsdownloader is bijgewerkt naar de nieuwste versie"],
        "no": ["Utvidelsen din for bildenedlasting ble oppdatert til den nyeste versjonen"],
        "pl": ["Twoje rozszerzenie do pobierania obrazów zostało zaktualizowane do najnowszej wersji"],
        "pt": ["Sua extensão de downloader de imagens foi atualizada para a versão mais recente"],
        "ro": ["Extensia dvs. de descărcare a imaginilor a fost actualizată la cea mai recentă versiune"],
        "ru": ["Ваше расширение для загрузки изображений было обновлено до последней версии"],
        "sk": ["Vaša rozšírenie na sťahovanie obrázkov bolo aktualizované na najnovšiu verziu"],
        "sl": ["Vaša razširitev za prenos slik je bila posodobljena na najnovejšo različico"],
        "sr": ["Ваше проширење за преузимање слика је ажурирано на најновију верзију"],
        "sv": ["Din tilläggsprogram för bildnedladdning har uppdaterats till den senaste versionen"],
        "sw": ["Kiendelezi chako cha kupakua picha kimesasishwa hadi toleo jipya zaidi"],
        "ta": ["உங்கள் நீட்டிப்பு படத்தைப் பதிவிறக்கி சமீபத்திய பதிப்பிற்கு புதுப்பிக்கப்பட்டது"],
        "te": ["మీ విస్తరణ చిత్రం డౌన్‌లోడర్‌ను తాజా వెర్షన్‌కు అప్డేట్ చేయబడింది"],
        "th": ["โปรแกรมเสริมดาวน์โหลดรูปภาพของคุณได้รับการอัปเดตเป็นเวอร์ชันล่าสุด"],
        "tr": ["Uzantınızın görüntü indiricisi en son sürüme güncellendi"],
        "uk": ["Ваше розширення для завантаження зображень було оновлено до останньої версії"],
        "vi": ["Tiện ích mở rộng tải xuống hình ảnh của bạn đã được cập nhật lên phiên bản mới nhất"],
        "zh_CN": ["您的扩展程序图像下载器已更新到最新版本"],
        "zh_TW": ["您的擴充功能圖像下載器已更新到最新版本"],
        "zu": ["Isandiso sakho sokulanda izithombe sibuyekeziwe kuya kunguqulo lwakamuva"]
    };
    
    const text = {
        "en": { 
            "p1": "We work hard to make it the best image download extension. If you are happy with our extension", 
            "p2": "please consider", 
            "p3": "rating the extension.",
            "p4": "what's new",
            "p5": "We improve the image capture mechanism - we now have less misses.",
            "p6": ""
        },
        "ar": { 
            "p1": "نعمل بجد لجعلها أفضل إضافة لتنزيل الصور. إذا كنت سعيدًا بإضافتنا",
            "p2": "يرجى النظر في",
            "p3": "تقييم الإضافة.",
            "p4": "ما الجديد",
            "p5": "لقد قمنا بتحسين آلية التقاط الصور - الآن لدينا أقل فرص للفشل.",
            "p6": ""
        },
        "am": { 
            "p1": "እኛ እንቀላቀል የምስል አውርዶ ቅንብሩን እንዲሆን እንድለምን፣ እንደ እርስዎ እንደ ተጠቃሚ",
            "p2": "እባክዎን ያስቡ",
            "p3": "እንድንጠቀም በመስክራት አድርጎት እንድናስቡ.",
            "p4": "አዲስ ምንድን ነው",
            "p5": "እኛ እንቀላቀል የምስል አውርዶ ቅንብሩን እንዲሆን እንድለምን፣ እንደ እርስዎ እንደ ተጠቃሚ",
            "p6": ""
        },
        "bg": { 
            "p1": "Работим усилено, за да го направим най-доброто разширение за изтегляне на изображения. Ако сте доволни от нашето разширение",
            "p2": "моля, обмислете",
            "p3": "да оцените разширението.",
            "p4": "какво ново",
            "p5": "Подобрихме механизма за улавяне на изображения - сега имаме по-малко пропуски.",
            "p6": ""
        },
        "bn": { 
            "p1": "আমরা এটিকে সেরা চিত্র ডাউনলোড এক্সটেনশন করতে কঠোর পরিশ্রম করি। আপনি যদি আমাদের এক্সটেনশন নিয়ে খুশি হন",
            "p2": "অনুগ্রহ করে বিবেচনা করুন",
            "p3": "এক্সটেনশনটি রেটিং করুন।",
            "p4": "কি নতুন",
            "p5": "আমরা চিত্র ক্যাপচার মেকানিজম উন্নত করেছি - এখন আমাদের মিসেস কম।",
            "p6": ""
        },
        "cs": { 
            "p1": "Tvrdě pracujeme na tom, aby to bylo nejlepší rozšíření pro stahování obrázků. Pokud jste s naším rozšířením spokojeni",
            "p2": "prosím zvažte",
            "p3": "hodnocení rozšíření.",
            "p4": "co je nového",
            "p5": "Vylepšili jsme mechanismus pro snímání obrázků – nyní máme méně chyb.",
            "p6": ""
        },
        "de": { 
            "p1": "Wir arbeiten hart daran, die beste Bild-Downloader-Erweiterung zu machen. Wenn Sie mit unserer Erweiterung zufrieden sind",
            "p2": "bitte erwägen",
            "p3": "die Erweiterung zu bewerten.",
            "p4": "was ist neu",
            "p5": "Wir haben den Bildaufnahmemechanismus verbessert – jetzt gibt es weniger Fehlversuche.",
            "p6": ""
        },
        "el": { 
            "p1": "Δουλεύουμε σκληρά για να το κάνουμε την καλύτερη επέκταση λήψης εικόνας. Εάν είστε ευχαριστημένοι με την επέκτασή μας",
            "p2": "παρακαλώ σκεφτείτε",
            "p3": "να αξιολογήσετε την επέκταση.",
            "p4": "τι νέο υπάρχει",
            "p5": "Βελτιώσαμε τον μηχανισμό λήψης εικόνας - τώρα έχουμε λιγότερα λάθη.",
            "p6": ""
        },
        "es": { 
            "p1": "Trabajamos duro para que sea la mejor extensión de descarga de imágenes. Si estás contento con nuestra extensión",
            "p2": "por favor considera",
            "p3": "calificar la extensión.",
            "p4": "qué hay de nuevo",
            "p5": "Hemos mejorado el mecanismo de captura de imágenes: ahora tenemos menos fallos.",
            "p6": ""
        },
        "fa": { 
            "p1": "ما سخت کار می‌کنیم تا بهترین افزونه دانلود تصویر را بسازیم. اگر از افزونه ما راضی هستید",
            "p2": "لطفا در نظر داشته باشید",
            "p3": "افزونه را ارزیابی کنید.",
            "p4": "چه چیزی جدید است",
            "p5": "ما مکانیزم ضبط تصویر را بهبود بخشیده‌ایم - اکنون اشتباهات کمتری داریم.",
            "p6": ""
        },
        "fil": { 
            "p1": "Nagsusumikap kami upang gawin itong pinakamahusay na pag-download ng imahe ng extension. Kung masaya ka sa aming extension",
            "p2": "mangyaring isaalang-alang",
            "p3": "na i-rate ang extension.",
            "p4": "ano ang bago",
            "p5": "Pinahusay namin ang mekanismo ng pagkuha ng imahe - mayroon na kaming mas kaunting mga pagkukulang.",
            "p6": ""
        },
        "fr": { 
            "p1": "Nous travaillons dur pour en faire la meilleure extension de téléchargement d'images. Si vous êtes satisfait de notre extension",
            "p2": "merci de considérer",
            "p3": "évaluer l'extension.",
            "p4": "quoi de neuf",
            "p5": "Nous avons amélioré le mécanisme de capture des images - nous avons maintenant moins d'échecs.",
            "p6": ""
        },
        "he": { 
            "p1": "אנחנו עובדים קשה כדי להפוך את זה לתוסף הורדת התמונות הטוב ביותר. אם אתה מרוצה מהתוסף שלנו",
            "p2": "אנא שקול",
            "p3": "לדרג את התוסף.",
            "p4": "מה חדש",
            "p5": "שיפרנו את מנגנון לכידת התמונות - עכשיו יש לנו פחות כשלונות.",
            "p6": ""
        },
        "hi": { 
            "p1": "हम इसे सबसे अच्छा छवि डाउनलोड एक्सटेंशन बनाने के लिए मेहनत कर रहे हैं। यदि आप हमारे एक्सटेंशन से खुश हैं",
            "p2": "कृपया विचार करें",
            "p3": "एक्सटेंशन को रेट करने का।",
            "p4": "नया क्या है",
            "p5": "हमने छवि कैप्चर तंत्र में सुधार किया है - अब हमारे पास कम विफलताएं हैं।",
            "p6": ""
        },
        "hr": { 
            "p1": "Trudimo se da ga učinimo najboljim proširenjem za preuzimanje slika. Ako ste zadovoljni s našim proširenjem",
            "p2": "molimo razmislite",
            "p3": "o ocjenjivanju proširenja.",
            "p4": "što je novo",
            "p5": "Poboljšali smo mehanizam za hvatanje slika - sada imamo manje promašaja.",
            "p6": ""
        },
        "hu": { 
            "p1": "Keményen dolgozunk azon, hogy a legjobb képletöltő kiterjesztést készítsük. Ha elégedett a kiterjesztésünkkel",
            "p2": "kérjük, fontolja meg",
            "p3": "a kiterjesztés értékelését.",
            "p4": "mi újság",
            "p5": "Fejlesztettük a képrögzítési mechanizmust – most kevesebb hiba van.",
            "p6": ""
        },
        "id": { 
            "p1": "Kami bekerja keras untuk menjadikannya ekstensi unduhan gambar terbaik. Jika Anda puas dengan ekstensi kami",
            "p2": "silakan pertimbangkan",
            "p3": "memberikan penilaian pada ekstensi.",
            "p4": "apa yang baru",
            "p5": "Kami telah meningkatkan mekanisme penangkapan gambar - sekarang kami memiliki lebih sedikit kesalahan.",
            "p6": ""
        },
        "it": { 
            "p1": "Lavoriamo sodo per renderlo la migliore estensione per il download di immagini. Se sei soddisfatto della nostra estensione",
            "p2": "per favore considera",
            "p3": "di valutare l'estensione.",
            "p4": "cosa c'è di nuovo",
            "p5": "Abbiamo migliorato il meccanismo di acquisizione delle immagini - ora abbiamo meno errori.",
            "p6": ""
        },
        "ja": { 
            "p1": "これを最高の画像ダウンロード拡張機能にするために一生懸命取り組んでいます。拡張機能に満足している場合は",
            "p2": "考慮してください",
            "p3": "拡張機能の評価。",
            "p4": "新着情報",
            "p5": "画像キャプチャメカニズムを改善しました - 今では失敗が少なくなりました。",
            "p6": ""
        },
        "ko": { 
            "p1": "이미지 다운로드 확장 기능을 최고의 확장 기능으로 만들기 위해 열심히 노력하고 있습니다. 확장 기능에 만족하신다면",
            "p2": "고려해 주세요",
            "p3": "확장 기능에 대한 평가를 남겨 주세요.",
            "p4": "새로운 기능",
            "p5": "이미지 캡처 메커니즘을 개선했습니다 - 이제 실수가 적어졌습니다.",
            "p6": ""
        },
        "lt": { 
            "p1": "Sunkiai dirbame, kad tai būtų geriausias vaizdų atsisiuntimo plėtinys. Jei esate patenkinti mūsų plėtiniu",
            "p2": "prašome apsvarstyti galimybę",
            "p3": "įvertinti plėtinį.",
            "p4": "kas naujo",
            "p5": "Patobulinome vaizdo fiksavimo mechanizmą – dabar turime mažiau klaidų.",
            "p6": ""
        },
        "ml": { 
            "p1": "ചിത്രം ഡൗൺലോഡ് ചെയ്യുന്നതിനുള്ള മികച്ച വിപുലീകരണം ആക്കാൻ ഞങ്ങൾ കടുത്ത പരിശ്രമം ചെയ്യുന്നു. ഞങ്ങളുടെ വിപുലീകരണത്തിൽ നിങ്ങൾ സന്തുഷ്ടനാണെങ്കിൽ",
            "p2": "ദയവായി പരിഗണിക്കുക",
            "p3": "വിപുലീകരണത്തെ റേറ്റുചെയ്യുക.",
            "p4": "പുതിയത് എന്താണ്",
            "p5": "ചിത്രം പിടിച്ചെടുക്കാനുള്ള സംവിധാനത്തെ ഞങ്ങൾ മെച്ചപ്പെടുത്തിക്കഴിഞ്ഞു - ഇപ്പോൾ നമുക്കുള്ള പരാജയങ്ങൾ കുറവാണ്.",
            "p6": ""
        },
        "mr": { 
            "p1": "आम्ही हे सर्वोत्तम प्रतिमा डाउनलोड एक्स्टेंशन बनवण्यासाठी कष्ट करतो. तुम्ही आमच्या एक्स्टेंशनने समाधानी असाल",
            "p2": "कृपया विचार करा",
            "p3": "एक्स्टेंशन रेट करा.",
            "p4": "नवीन काय आहे",
            "p5": "आम्ही प्रतिमा कॅप्चर यंत्रणा सुधारली आहे - आता आमच्याकडे कमी चुका आहेत.",
            "p6": ""
        },
        "ne": { 
            "p1": "हामी यसलाई उत्तम छवि डाउनलोड विस्तार बनाउन कडा मेहनत गर्छौं। यदि तपाइँ हाम्रो विस्तार संग खुसी हुनुहुन्छ भने",
            "p2": "कृपया विचार गर्नुहोस्",
            "p3": "विस्तारलाई मूल्याङ्कन गर्नुहोस्।",
            "p4": "नयाँ के छ",
            "p5": "हामीले छवि क्याप्चर मेकानिज्ममा सुधार गरेका छौं - अब हाम्रा गल्तीहरू कम छन्।",
            "p6": ""
        },
        "nl": { 
            "p1": "We werken hard om het de beste extensie voor het downloaden van afbeeldingen te maken. Als u tevreden bent met onze extensie",
            "p2": "overweeg dan alstublieft",
            "p3": "de extensie te beoordelen.",
            "p4": "wat is er nieuw",
            "p5": "We hebben het mechanisme voor het vastleggen van afbeeldingen verbeterd - we hebben nu minder fouten.",
            "p6": ""
        },
        "no": { 
            "p1": "Vi jobber hardt for å gjøre det til den beste bilde-nedlastingsutvidelsen. Hvis du er fornøyd med utvidelsen vår",
            "p2": "vennligst vurder",
            "p3": "å vurdere utvidelsen.",
            "p4": "hva er nytt",
            "p5": "Vi har forbedret bildeopptaksmekanismen - nå har vi færre feil.",
            "p6": ""
        },
        "pa": { 
            "p1": "ਅਸੀਂ ਇਸਨੂੰ ਸਭ ਤੋਂ ਵਧੀਆ ਚਿੱਤਰ ਡਾਊਨਲੋਡ ਐਕਸਟੈਂਸ਼ਨ ਬਣਾਉਣ ਲਈ ਕਠਿਨ ਮਿਹਨਤ ਕਰ ਰਹੇ ਹਾਂ। ਜੇ ਤੁਸੀਂ ਸਾਡੇ ਐਕਸਟੈਂਸ਼ਨ ਨਾਲ ਖੁਸ਼ ਹੋ",
            "p2": "ਕਿਰਪਾ ਕਰਕੇ ਵਿਚਾਰ ਕਰੋ",
            "p3": "ਐਕਸਟੈਂਸ਼ਨ ਨੂੰ ਰੇਟ ਕਰਨ ਲਈ।",
            "p4": "ਨਵਾਂ ਕੀ ਹੈ",
            "p5": "ਅਸੀਂ ਚਿੱਤਰ ਕੈਪਚਰ ਮਕੈਨਿਜ਼ਮ ਵਿੱਚ ਸੁਧਾਰ ਕੀਤਾ ਹੈ - ਹੁਣ ਸਾਡੇ ਕੋਲ ਘੱਟ ਗਲਤੀਆਂ ਹਨ।",
            "p6": ""
        },
        "pl": { 
            "p1": "Ciężko pracujemy, aby uczynić go najlepszym rozszerzeniem do pobierania obrazów. Jeśli jesteś zadowolony z naszego rozszerzenia",
            "p2": "proszę rozważ",
            "p3": "ocenę rozszerzenia.",
            "p4": "co nowego",
            "p5": "Udoskonaliliśmy mechanizm przechwytywania obrazów – teraz mamy mniej błędów.",
            "p6": ""
        },
        "pt": { 
            "p1": "Trabalhamos arduamente para torná-lo a melhor extensão de download de imagens. Se estiver satisfeito com nossa extensão",
            "p2": "por favor, considere",
            "p3": "avaliar a extensão.",
            "p4": "o que há de novo",
            "p5": "Melhoramos o mecanismo de captura de imagens - agora temos menos falhas.",
            "p6": ""
        },
        "pt_br": { 
            "p1": "Trabalhamos arduamente para torná-lo a melhor extensão de download de imagens. Se você estiver satisfeito com nossa extensão",
            "p2": "por favor, considere",
            "p3": "avaliar a extensão.",
            "p4": "o que há de novo",
            "p5": "Melhoramos o mecanismo de captura de imagens - agora temos menos falhas.",
            "p6": ""
        },
        "ro": { 
            "p1": "Lucrăm din greu pentru a-l face cea mai bună extensie de descărcare de imagini. Dacă sunteți mulțumit de extensia noastră",
            "p2": "vă rugăm să luați în considerare",
            "p3": "evaluarea extensiei.",
            "p4": "ce este nou",
            "p5": "Am îmbunătățit mecanismul de capturare a imaginilor - acum avem mai puține erori.",
            "p6": ""
        },
        "ru": { 
            "p1": "Мы усердно работаем, чтобы сделать его лучшим расширением для загрузки изображений. Если вы довольны нашим расширением",
            "p2": "пожалуйста, рассмотрите возможность",
            "p3": "оценить расширение.",
            "p4": "что нового",
            "p5": "Мы улучшили механизм захвата изображений - теперь у нас меньше ошибок.",
            "p6": ""
        },
        "sv": { 
            "p1": "Vi arbetar hårt för att göra det till det bästa bildnedladdningstillägget. Om du är nöjd med vårt tillägg",
            "p2": "överväg att",
            "p3": "betygsätta tillägget.",
            "p4": "vad är nytt",
            "p5": "Vi har förbättrat bildinspelningsmekanismen - nu har vi färre fel.",
            "p6": ""
        },
        "ta": { 
            "p1": "சிறந்த பட பதிவிறக்க நீட்டிப்பாக இதை உருவாக்க நாம் கடினமாக உழைக்கிறோம். எங்கள் நீட்டிப்பால் நீங்கள் மகிழ்ச்சியடைந்தால்",
            "p2": "தயவுசெய்து பரிசீலிக்கவும்",
            "p3": "நீட்டிப்பை மதிப்பீடு செய்ய.",
            "p4": "புதியது என்ன",
            "p5": "நாங்கள் படங்களைப் பிடிக்கும் முறைமையை மேம்படுத்தியுள்ளோம் - இப்போது நமக்குக் குறைவான தவறுகள் உள்ளன.",
            "p6": ""
        },
        "th": { 
            "p1": "เราทำงานอย่างหนักเพื่อทำให้เป็นส่วนขยายการดาวน์โหลดรูปภาพที่ดีที่สุด หากคุณพอใจกับส่วนขยายของเรา",
            "p2": "โปรดพิจารณา",
            "p3": "ให้คะแนนส่วนขยาย",
            "p4": "อะไรใหม่",
            "p5": "เราได้ปรับปรุงกลไกการจับภาพ - ตอนนี้เรามีความล้มเหลวน้อยลง",
            "p6": ""
        },
        "tr": { 
            "p1": "Onu en iyi görüntü indirme uzantısı yapmak için çok çalışıyoruz. Uzantımızdan memnunsanız",
            "p2": "lütfen düşünün",
            "p3": "uzantıyı değerlendirmeyi düşünün.",
            "p4": "yenilikler",
            "p5": "Görüntü yakalama mekanizmasını geliştirdik - artık daha az hata yapıyoruz.",
            "p6": ""
        },
        "uk": { 
            "p1": "Ми наполегливо працюємо, щоб зробити його найкращим розширенням для завантаження зображень. Якщо ви задоволені нашим розширенням",
            "p2": "будь ласка, розгляньте можливість",
            "p3": "оцінити розширення.",
            "p4": "що нового",
            "p5": "Ми покращили механізм захоплення зображень - тепер у нас менше помилок.",
            "p6": ""
        },
        "ur": { 
            "p1": "ہم اسے بہترین تصویر ڈاؤن لوڈ ایکسٹینشن بنانے کے لیے سخت محنت کر رہے ہیں۔ اگر آپ ہماری ایکسٹینشن سے خوش ہیں",
            "p2": "براہ کرم غور کریں",
            "p3": "ایکسٹینشن کی درجہ بندی کرنا۔",
            "p4": "نیا کیا ہے",
            "p5": "ہم نے تصویر کیپچر کرنے کا طریقہ بہتر کیا ہے - اب ہمارے پاس کم غلطیاں ہیں۔",
            "p6": ""
        },
        "vi": { 
            "p1": "Chúng tôi làm việc chăm chỉ để biến nó thành tiện ích mở rộng tải xuống hình ảnh tốt nhất. Nếu bạn hài lòng với tiện ích mở rộng của chúng tôi",
            "p2": "vui lòng xem xét",
            "p3": "đánh giá tiện ích mở rộng.",
            "p4": "có gì mới",
            "p5": "Chúng tôi đã cải thiện cơ chế chụp ảnh - giờ chúng tôi có ít lỗi hơn.",
            "p6": ""
        },
        "zh_CN": { 
            "p1": "我们努力使其成为最佳的图片下载扩展。如果您对我们的扩展程序感到满意",
            "p2": "请考虑",
            "p3": "为扩展程序评分。",
            "p4": "更新内容",
            "p5": "我们改进了图像捕获机制——现在我们更少出错。",
            "p6": ""
        },
        "zh_TW": { 
            "p1": "我們努力使其成為最佳的圖片下載擴展。如果您對我們的擴展感到滿意",
            "p2": "請考慮",
            "p3": "為擴展打分。",
            "p4": "更新內容",
            "p5": "我們改進了圖像捕獲機制——現在我們更少出錯。",
            "p6": ""
        },
        "zu": { 
            "p1": "Sisebenza kanzima ukwenza lokhu kube isandiso sokulanda isithombe esihle kakhulu. Uma ujabule ngesandiso sethu",
            "p2": "sicela ucabangele",
            "p3": "ukulinganisa isandiso.",
            "p4": "okusha",
            "p5": "Sithuthukise indlela yokuthwebula izithombe - manje sinezinkinga ezimbalwa.",
            "p6": ""
        }
    }
    
    
            
    const { browser, lang } = useParams();
    let url ="https://chromewebstore.google.com/detail/image-downloader-save-all/fpelahbljekknahkcaegamhcndkfpfnc";
    if(browser=="edge"){
        url ="https://microsoftedge.microsoft.com/addons/detail/image-downloader-save-a/flbolonkboilcbnohjlchlfgdalomdpl"
    }else if(browser=="opera"){
        url="opera"
    }
    let header = headers[lang]; 
    let p1 = text[lang]['p1'];
    let p2 = text[lang]['p2'];
    let p3 = text[lang]['p3'];
    let p4 = text[lang]['p4'];
    let p5 = text[lang]['p5'];
    let p6 = text[lang]['p6'];
    let enLink = "../image_downloader-rate_us/"+browser+"/en";
        return(
        <div  style={{backgroundImage:"URL(/../assets/meercat-download-images.jpeg)", width:"1024px", height:"1024px"}}>
            <NavLink to="/en/contact"><img src="/../assets/email-icon.svg" style={{float:"right", width:"48px", margin:"16px"}} /></NavLink>
            {lang=='en'?"":<NavLink to= {enLink}><img src="/../assets/en.svg" style={{float:"right", width:"44px", margin:"24px 12px"}} /></NavLink>}
            <div style={{color:"white",margin:"48px"}}>
<br/>
<h1 style={{backgroundColor:"rgb(0,0,0,0.6)", padding:"4px"}}>{header}</h1>
<h2>
    <div style={{backgroundColor:"rgb(0,0,0,0.6)", paddingLeft:"8px"}}><p><h2>{p4}</h2>{p5}<br /><br />{p6}</p>{p1} {p2} <a href={url} style={{padding:"4px",color:"orange"}}>{p3}</a></div>
</h2>
</div>
        </div>
        )
}